<template>
  <div>
    <Inventory :pageType="pageType"></Inventory>
  </div>
</template>

<script>
import Inventory from "@/views/modules/collection/collect/inventory.vue";

export default {
    name: "museum",
    components: {Inventory},
    data(){
        return{
            pageType:'museum',
        }
    }
}
</script>

<style scoped>

</style>